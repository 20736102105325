<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="expert">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6">
                <div class="form-inline navbar-search">
                  <div class="input-group">
                    <input
                      v-on:keyup="search"
                      name="title"
                      class="form-control bg-light border-0 small"
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                      v-model="filter"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="searchFor">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="all-tabs">
                  <div
                    :class="activeTab == 'all' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('all');
                      currentPage = 1;
                    "
                    class="mx-2 opt"
                  >
                    All
                  </div>
                  |
                  <div
                    :class="activeTab == 'verified' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('verified');
                      currentPage = 1;
                    "
                    class="mx-2 opt text-success"
                  >
                    Verified
                  </div>
                  |
                  <div
                    :class="activeTab == 'rejected' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('rejected');
                      currentPage = 1;
                    "
                    class="mx-2 opt text-danger"
                  >
                    Rejected
                  </div>
                  |
                  <div
                    :class="activeTab == 'pending' ? ' tab-options' : ''"
                    @click.prevent="
                      fetchData('pending');
                      currentPage = 1;
                    "
                    class="mx-2 opt text-secondary"
                  >
                    Pending
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :per-page="0"
                :current-page="currentPage"
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(pan_card_image)="row">
                  <b-button
                    class="btn btn-sm btn-primary"
                    variant="primary"
                    @click="showImageModal(row.item.pan_card_image)"
                    :disabled="row.item.pan_card_image ? false : true"
                    >View image
                  </b-button>
                </template>
                <template v-slot:cell(bank_cheque_image)="row">
                  <b-button
                    class="btn btn-sm btn-primary"
                    variant="primary"
                    @click="showImageModal(row.item.bank_cheque_image)"
                    :disabled="row.item.bank_cheque_image ? false : true"
                    >View image
                  </b-button>
                </template>
                <template v-slot:cell(verification_status)="row">
                  <span
                    class="badge badge-info p-2"
                    v-if="row.item.verification_status == 0"
                    >Not Uploaded</span
                  >
                  <span
                    class="badge badge-success p-2"
                    v-if="row.item.verification_status == 1"
                    >Verified</span
                  >
                  <b-button
                    class="btn btn-sm btn-secondary"
                    variant="secondary"
                    v-if="row.item.verification_status == 2"
                    @click="verificationModal(row.item)"
                    >Pending
                  </b-button>
                  <span
                    class="badge badge-danger p-2"
                    v-if="row.item.verification_status == 3"
                    >Rejected</span
                  >
                </template>
                <template v-slot:cell(is_reject)="row">
                  <template v-if="row.item.is_reject">
                    <b-button
                      class="btn btn-sm btn-primary"
                      variant="primary"
                      @click="RejectReasonsModal(row.item.id)"
                      >view
                    </b-button>
                  </template>
                  <template v-else>
                    <span>---</span>
                  </template>
                </template>
                <template v-slot:cell(agreement)="row">
                  <b-button
                    class="btn btn-sm"
                    variant="primary"
                    target="_blank"
                    :href="row.item.agreement"
                    :disabled="
                      row.item.agreement === '' || row.item.agreement === null
                    "
                  >
                    View Agreement
                  </b-button>
                </template>
                <template v-slot:cell(upload)="row">
                  <i class="fa fa-upload text-primary" @click="uploadImageModal(row.item.id)"></i>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    >
                    </b-pagination>
                  </ul>
                </div>
                <div class="form-inline navbar-search">
                  <span class="mr-2">Go to a Particular Paginated Page :</span>
                  <div class="input-group">
                    <b-form-input
                      id="case"
                      v-model="pageNo"
                      placeholder="Enter Page No"
                      class="in-width"
                    >
                    </b-form-input>
                    <div class="input-group-append">
                      <button class="btn btn-search" @click="filterPage">
                        <i class="fas fa-search fa-sm"> </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <b-modal
            id="bvModal"
            v-model="showVerificationModal"
            title="MCI Verification"
            hide-footer
            centered
            @hidden="clearVerificationModel"
          >
            <template #modal-title
              ><i
                class="mdi mdi-check-circle-outline"
                style="color: #7182df; font-size: 20px"
              ></i>
              Verification
            </template>
            <div class="col-12 d-flex">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col class="d-flex justify-content-end">
                    <b-form-radio
                      v-model="form.verification_status"
                      name="verificationStatus"
                      :value="1"
                      @change="handleVerificationChange"
                    >
                      Accept
                    </b-form-radio></b-col
                  >
                  <b-col>
                    <b-form-radio
                      v-model="form.verification_status"
                      name="verificationStatus"
                      :value="3"
                      @change="handleVerificationChange"
                    >
                      Reject
                    </b-form-radio></b-col
                  >
                </b-row>
                <b-row v-if="form.verification_status === 3">
                  <b-form-group
                    id="input-group-1"
                    label-for="input-1"
                    class="col-12"
                  >
                    <label style=""
                      >Note <span style="color: red">*</span></label
                    >
                    <b-form-textarea
                      id="input-1"
                      placeholder="Enter Note"
                      class="col-12"
                      rows="3"
                      v-model="form.reason"
                    ></b-form-textarea>
                  </b-form-group>
                </b-row>
                <b-row class="d-flex justify-content-end">
                  <section class="mt-2">
                    <b-button
                      class="btn btn-success"
                      variant="success"
                      :disabled="form.verification_status === null"
                      @click="SumbitVerificationStatus"
                    >
                      Submit
                    </b-button>
                  </section>
                </b-row>
              </b-container>
            </div>
          </b-modal>

          <b-modal
            id="bvModal"
            size="lg"
            v-model="showRejectReasonModal"
            title="Reject Reasons"
            hide-footer
            centered
            @hidden="clearReasonModel"
          >
            <template #modal-title
              ><i
                class="mdi mdi-information-outline"
                style="color: #7182df; font-size: 20px"
              ></i>
              Reject Reasons
            </template>
            <div
              style="max-height: 70vh !important; overflow-y: auto !important"
            >
              <ul v-for="(item, index) in rejectReasons" :key="index">
                <li class="p-2 rounded border">
                  <div class="d-flex justify-content-between">
                    {{ item.reason }}
                    <section>
                      {{ getSessionStart(item.created_at) }}
                    </section>
                  </div>
                </li>
              </ul>
            </div>
          </b-modal>

          <b-modal
            id="bvModal"
            v-model="imageModal"
            hide-footer
            centered
            @hidden="clearImageModal"
          >
            <template #modal-title
              ><i
                class="mdi mdi-information-outline"
                style="color: #7182df; font-size: 20px"
              ></i>
              Image
            </template>
            <!-- <div class="d-flex justify-content-center">
              <viewer :images="[image]">
                <img
                  :src="image"
                  width="415px"
                  height="215px"
                  style="object-fit: cover; margin-right: 10px"
                  class="border"
                />
              </viewer> 
            </div> -->
            <div class="d-flex justify-content-center">
              <template v-if="isPdf">
                <a :href="image" target="_blank" rel="noopener noreferrer">
                  Open PDF
                </a>
              </template>
              <template v-else>
                <viewer :images="[image]">
                  <img
                    :src="image"
                    width="415px"
                    height="215px"
                    style="object-fit: cover; margin-right: 10px"
                    class="border"
                  />
                </viewer>
              </template>
            </div>
          </b-modal>
          <b-modal id="bvuploadImageModal" ref="imageUpload" title="Upload Image [Max 150KB allowed]"
          v-model="uploadimageModal" no-close-on-backdrop centered hide-header-close hide-footer @hidden="clearUploadImageModal">
          <div class="">
            <b-form-file id="input-5" ref="fileInput" accept=".png" placeholder="Choose a file or drop it here..."
              @change="handleFileUpload($event)"></b-form-file>

          </div>
          <div class="mt-4 d-flex justify-content-end">
            <b-button @click="clearUploadImageModal" variant="secondary" class="mr-2" v-if="!isProgress">
              Cancel
            </b-button>
            <b-button @click="updateData()" variant="primary" :disabled="!bank_chqeue_image">
              Upload
            </b-button>
          </div>
        </b-modal>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import panMixin from "../../../mixins/ModuleJs/pancard";
import moment from "moment";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  mixins: [MixinRequest, panMixin],
  data() {
    return {
      title: "PAN CARD",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  methods: {
    getSessionStart: function (session_time) {
      let dateTime1 = moment(session_time).format("MMM D, YYYY ");
      return dateTime1;
    },
    show(img) {
      this.$viewerApi({
        images: [img],
      });
    },
  },
};
</script>
